import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '@/stores/auth';
import authService from '@/services/auth';

const isAuthenticated = async (to, from, next) => {
  const auth = useAuthStore();
  try {
    if (auth.token) {
      auth.user = await authService.getUserData();
      const authenticated = await auth.verifyAndUpdateToken();

      if (!authenticated) {
        return next('/sem_acesso');
      } else {
        next();
      }
    } else {
      await auth.login();
      next(false);
    }
  } catch (e) {
    return next('/sem_acesso');
  }
};

const isAuthenticatedUser = async (to, from, next) => {
  const auth = useAuthStore();

  try {
    if (auth.token) {
      auth.user = await authService.getUserData();

      const authenticated = await auth.verifyAndUpdateTokenUser();

      if (!authenticated) return next('/sem_acesso');

      let registrador = false;
      for (let i = 0; i < auth.user.grupos.length; i++) {
        let arrayGrupos = auth.user.grupos[i].split('/');
        arrayGrupos = arrayGrupos.filter((grupo) => grupo);

        if (
          (arrayGrupos.length === 1 && arrayGrupos[0].toLowerCase() === 'registrador civil') ||
          arrayGrupos[0].toLowerCase() === 'teste registrador civil'
        ) {
          registrador = true;
          break;
        }
      }

      if (registrador) return next();

      if (to.path !== '/') return next();

      return next('/cidadao');
    } else {
      await auth.login();
      next(false);
    }
  } catch (e) {
    return next('/sem_acesso');
  }
};

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'Plataforma de requerimentos',
      component: () => import('../views/PlataformaRequerimentos.vue'),
      beforeEnter: isAuthenticatedUser,
    },
    {
      path: '/plataforma-requerimento/requerimento/:id?/:edit?',
      name: 'Novo requerimento',
      component: () => import('../views/Requerimento.vue'),
      beforeEnter: isAuthenticated,
    },
    {
      path: '/plataforma-requerimento/gerado/:id/:link?',
      name: 'Requerimento gerado',
      component: () => import('../views/RequerimentoGerado.vue'),
      beforeEnter: isAuthenticated,
    },
    {
      path: '/plataforma-requerimento/assinatura/:id',
      name: 'Assinar requerimento',
      component: () => import('../views/AssinarRequerimento.vue'),
      beforeEnter: isAuthenticatedUser,
    },
    {
      path: '/cidadao',
      name: 'Painel de requerimentos',
      component: () => import('../views/PainelRequerimentos.vue'),
      beforeEnter: isAuthenticatedUser,
    },
    {
      path: '/cidadao/requerimento/:id?/:edit?',
      name: 'Novo requerimento cidadão',
      component: () => import('../views/RequerimentoCidadao.vue'),
      beforeEnter: isAuthenticatedUser,
    },
    {
      path: '/sem_acesso',
      name: 'sem_acesso',
      component: () => import('../views/NotAuthorizedView.vue'),
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  console.log(`[${new Date().toLocaleString()}] Route ${from.fullPath} to ${to.fullPath}`);

  const auth = useAuthStore();

  const { logout } = to.query;

  if (logout) {
    auth.logoutCallback();
    return false;
  }

  next();
});

export default router;
