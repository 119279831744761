const Helper = {
  deepClone: (obj) => {
    return JSON.parse(JSON.stringify(obj));
  },
  // Retorna alterações no novo obj
  changedData: (newObj, oldObj) => {
    const keys = Object.keys(newObj);
    const result = {};

    for (const key of keys) {
      if (newObj[key] !== oldObj[key]) {
        result[key] = newObj[key];
      }
    }

    return result;
  },
  maskForm: (data, mask) => {
    if (!data) return;
    const dataSplit = data.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '').split('');
    dataSplit.forEach((character) => {
      mask = mask.replace('#', character);
    });
    return mask;
  },
  formatarCPF(cpf) {
    if (!cpf) return;
    const cpfLimpo = cpf.replace(/\D/g, '');
    if (cpfLimpo && cpfLimpo?.length === 11) {
      return (
        cpfLimpo.substring(0, 3) +
        '.' +
        cpfLimpo.substring(3, 6) +
        '.' +
        cpfLimpo.substring(6, 9) +
        '-' +
        cpfLimpo.substring(9)
      );
    }
  },
  maskDateFormat: (data) => {
    if (!data) return;
    return new Date(data).toLocaleDateString('pt-BR', { timeZone: 'UTC' });
  },
  maskDateHourFormat: (data) => {
    if (!data) return '-';

    const date = new Date(data);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}`;
  },
  validarCPF(cpf) {
    cpf = cpf.replace(/\D/g, '');
    if (cpf.length !== 11) {
      return false;
    }
    if (/^(\d)\1+$/.test(cpf)) {
      return false;
    }
    let sum = 0;
    for (let i = 0; i < 9; i++) {
      sum += parseInt(cpf.charAt(i)) * (10 - i);
    }
    let remainder = 11 - (sum % 11);
    let digit1 = remainder === 10 || remainder === 11 ? 0 : remainder;
    sum = 0;
    for (let i = 0; i < 10; i++) {
      sum += parseInt(cpf.charAt(i)) * (11 - i);
    }
    remainder = 11 - (sum % 11);
    let digit2 = remainder === 10 || remainder === 11 ? 0 : remainder;
    return parseInt(cpf.charAt(9)) === digit1 && parseInt(cpf.charAt(10)) === digit2;
  },

  dateStringBrFormat(field) {
    if (!field) return;
    const partes = field.split('-');
    const ano = partes[0];
    const mes = partes[1];
    const dia = partes[2];

    return dia + '/' + mes + '/' + ano;
  },

  formatarMatricula(dados) {
    if (!dados) return '';
    const pattern = /(\d{6})(\d{2})(\d{2})(\d{4})(\d{1})(\d{5})(\d{3})(\d{7})(\d{2})/;
    return dados.replace(pattern, '$1 $2 $3 $4 $5 $6 $7 $8 $9');
  },

  retornarDadosPDF: async (arquivo) => {
    const pdfWorker = await import('pdfjs-dist/build/pdf.worker');
    const pdfJS = await import('pdfjs-dist/build/pdf.min');
    pdfJS.GlobalWorkerOptions.workerSrc = pdfWorker;

    return await pdfJS.getDocument(URL.createObjectURL(arquivo)).promise;
  },

  aplicarMascaraDinamica(value, formato) {
    value = value.replace(/[^a-zA-Z0-9]/g, '');

    let resultado = '';
    let valorIndex = 0;

    for (let i = 0; i < formato.length; i++) {
      if (valorIndex >= value.length) break;

      const caractereFormato = formato[i];

      if (caractereFormato === '0') {
        resultado += value[valorIndex++];
      } else {
        resultado += caractereFormato;
      }
    }

    return resultado;
  },

  formatarData(dataISO) {
    const data = new Date(dataISO);
    const dia = String(data.getUTCDate()).padStart(2, '0');
    const mes = String(data.getUTCMonth() + 1).padStart(2, '0');
    const ano = data.getUTCFullYear();
    return `${dia}/${mes}/${ano}`;
  },
};

export default Helper;
