import { defineStore } from 'pinia';
import authService from '@/services/auth';
import jwt_decode from 'jwt-decode';
import Cookies from 'js-cookie';

let refreshTokenTimeout = null;

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    token: Cookies.get('application-token')
      ? JSON.parse(atob(Cookies.get('application-token'))).message
      : null,
    user: null,
    socketBooted: false,
    logoutByLOAMessage: getLogoutByLOAMessage(),
    userPhotoProfile: null,
  }),
  actions: {
    // level (nível de garantia): baixo, substancial, alto
    async login(level = '') {
      const params = new URLSearchParams({
        redirectUrl: window.location.href,
        level,
      });

      window.location.href = (await this.logoutByLOA())
        ? window.location.origin
        : `${import.meta.env.VITE_API_URL}/login?${params.toString()}`;
    },
    async verifyLogin() {
      try {
        await this.verifyAndUpdateToken();
        if (!this.token) return this.login();

        return true;
      } catch (e) {
        // await this.logoutCallback();
        return false;
      }
    },
    logout() {
      const params = new URLSearchParams({
        redirectUrl: location.protocol + '//' + location.host,
      });

      window.location.href = `${import.meta.env.VITE_API_URL}/logout?${params.toString()}`;
    },
    urlLogin() {
      const params = new URLSearchParams({
        redirectUrl: window.location.href,
      });

      return this.logoutByLOA()
        ? location.origin
        : `${import.meta.env.VITE_API_URL}/login?${params.toString()}`;
    },
    urlLogout() {
      const params = new URLSearchParams({
        redirectUrl: location.protocol + '//' + location.host,
      });

      return `${import.meta.env.VITE_API_URL}/logout?${params.toString()}`;
    },
    logoutCallback() {
      this.token = null;
      this.user = null;
      Cookies.remove('application-token');
      localStorage.removeItem('cartorio');
    },
    logoutByLOA() {
      return !!Cookies.get('logout_by_LOA');
    },
    updateToken() {
      this.token = jwt_decode(getToken());
    },
    async verifyAndUpdateToken() {
      try {
        await authService.verifyAndUpdateToken();
        this.token = jwt_decode(getToken());
        this.refreshToken();
        this.updateToken();

        return true;
      } catch (e) {
        // await this.logoutCallback();
        return false;
      }
    },
    async verifyAndUpdateTokenUser() {
      try {
        await authService.verifyAndUpdateTokenUser();
        this.token = jwt_decode(getToken());
        this.refreshToken(true);
        this.updateToken();

        return true;
      } catch (e) {
        // await this.logoutCallback();
        return false;
      }
    },
    refreshToken(citizen = false) {
      clearTimeout(refreshTokenTimeout);
      const currentDate = new Date();
      const expiresAt = new Date(0);
      expiresAt.setUTCSeconds(this.token.expires_at);
      const executeAt = expiresAt - currentDate - 60;

      if (citizen) {
        refreshTokenTimeout = setTimeout(async () => {
          await this.verifyAndUpdateTokenUser();
        }, executeAt);
      } else {
        refreshTokenTimeout = setTimeout(async () => {
          await this.verifyAndUpdateToken();
        }, executeAt);
      }
    },
    async ensureLOA(level) {
      if (level === 'substancial' && this.token.acr === 'urn:idrc:loa:baixo') {
        return this.login(level);
      }

      if (level === 'alto' && this.token.acr !== 'urn:idrc:loa:alto') {
        return this.login(level);
      }
    },
    clearLOAMessage() {
      Cookies.remove('logout_by_LOA');
      this.logoutByLOAMessage = false;
    },
    getToken() {
      const token = Cookies.get('application-token');
      return token && JSON.parse(atob(token)).message;
    },
  },
});

function getToken() {
  try {
    const token = Cookies.get('application-token');
    return token && JSON.parse(atob(token)).message;
  } catch (e) {
    console.log(e);
  }
}

function getLogoutByLOAMessage() {
  try {
    const token = Cookies.get('logout_by_LOA');
    return token && decodeURIComponent(JSON.parse(atob(token)).message);
  } catch (e) {
    console.error(e);
  }
}
